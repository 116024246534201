import * as React from "react";
import PageLayout from "../components/common/PageLayout";

const NotFoundPage = () => {
  return (
    <PageLayout title="Page not found (404)">
      <main>
        <h1 className="container my-40 text-6xl text-center font-bold">
          Page not found
        </h1>
      </main>
    </PageLayout>
  );
};

export default NotFoundPage;
